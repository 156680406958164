

.advertising-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: #f4f4f4; /* Light background for contrast */
    min-height: 100vh;
  }
  
  .advertising-header {
    margin-bottom: 20px;
  }
  
  .advertising-header h2 {
    color: #2c3e50; /* Dark color for the header */
    font-size: 2.5rem; /* Larger font size for the header */
    text-transform: uppercase; /* Uppercase for a more formal look */
    border-bottom: 3px solid #3498db; /* Underline effect */
    padding-bottom: 10px;
    display: inline-block; /* Aligns the border to the text */
  }
  
  .advertising-content p {
    color: #34495e; /* Slightly lighter color for the content */
    font-size: 1.2rem; /* Comfortable reading size */
    line-height: 1.6; /* Adequate line spacing for readability */
    text-align: center; /* Centered text for a balanced look */
    max-width: 700px; /* Max width for optimal reading line length */
  }
  