/* AboutUs.css */



.about-us {
    background-color: #141c32;
    padding: 40px;
  }

/* Center the AboutUs content vertically and horizontally */
.about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #141c32;
  }
  
  /* Style the video container */
  .video-container {
    margin-top: 20px; /* Add spacing above the video */
  }
  
  
  .about-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding-top: 50px;
  }
  
  .about-content h1 {
    font-size: 35px;
    color: honeydew;
  }

  .about-us h2 {
    font-size: 35px;
    color: honeydew;
  }
  
  .about-content p {
    font-size: 25px;
    color: white;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: rgba(9, 17, 39, 0.6) !important;
  }
  