/* ProductList.css

.card {
    margin-bottom: 20px;
  }
  
  .card-title {
    font-size: 1.2rem;
  }
  
  .card-text {
    font-size: 1rem;
  }
  
  .btn {
    font-size: 1rem;
  }
   */


   /* ProductList.css */
/* ProductList.css */



.product-list-container {
    padding-top: 100px;
    min-height: 100vh;
  }
  
  .product-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-decoration: none;
    margin: 20px;
  }
  
  .product-card img {
    max-width: 50%;
    margin: auto;
    border-radius: 5px;
  }
  
  .product-card h1 {
    font-size: 30px;
    margin: 10px 0;
    text-align: center;
    color: white;
  }
  
  .product-card p {
    margin: 15px 10px;
    color: white;
    font-size: 18px;
    text-align: center;
  }
  
  /* Add additional styles as needed */
  
  