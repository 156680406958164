

.your-growth {
  background-color: #141c32 !important; /* Dark background for contrast */
  padding: 60px 0;
  text-align: center;
}

.your-growth h2 {
  font-size: 36px; /* Larger font size for heading */
  margin-bottom: 30px; /* More space below the heading */
  color: #f9f9f9;
}

.your-growth h2 .text-primary {
  color: #1A9CD9; /* Bright blue for "Your" */
}

.your-growth h2 .text-warning {
  color: #FFDF00; /* Bright yellow for "Growth" */
}

.growth-box {
  background-color: rgba(9, 17, 39, 0.6) !important; /* Semi-transparent white for elegance */
  border-radius: 10px; /* Rounded corners */
  padding: 30px; /* Spacious padding */
  margin: 0 10px;
  box-shadow: 0px 4px 6px rgba(9, 17, 39);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.growth-box:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.5); /* Increased shadow on hover */
  background-color: #1A9CD9 !important;
}

.growth-box h1 {
  font-size: 28px; /* Larger font size for sub-heading */
  margin-bottom: 15px; /* Space below sub-heading */
  color: #FFDF00; /* Bright yellow for titles */
}

.growth-box p, .growth-box h4 {
  font-size: 18px; /* Comfortable reading size */
  color: #f9f9f9; /* Light text for readability */
  margin-bottom: 8px; /* Space between paragraphs */
}

/* @media screen and (max-width: 768px) {
  .your-growth {
    padding: 40px 0;
  }

  .growth-box {
    margin: 15px 0;
    padding: 20px; 
  }

  .growth-box h1 {
    font-size: 24px; 
  }

  .growth-box p, .growth-box h4 {
    font-size: 16px; 
  }
} */

@media screen and (max-width: 576px) {
  /* Styles for extra small devices (phones) */
  .growth-box h1 {
    font-size: 22px; /* Smaller font size for headings on mobile */
  }

  .growth-box {
    padding: 15px; /* Adjust padding for smaller screens */
  }
}

@media screen and (max-width: 768px) {
  /* Styles for small to medium devices (large phones, tablets) */
  .growth-box h1 {
    font-size: 24px; /* Slightly larger font size for larger devices */
  }
}
