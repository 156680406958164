/* Add styles for the video and text sections */



.portfolio {
  display: flex;
  width: 100%;
}

.video-section,
.text-section {
  flex: 1;
  padding: 16px;
}

.video-section {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.video-row {
  display: flex;
  width: 100%;
}

.video-container {
  flex: 1;
  padding: 0;
  overflow: hidden;
  position: relative;
  transition: flex 0.3s;
}

.video {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
}

.video-container:hover {
  flex: 3; /* Expanded video takes up more space */
}

.video-container:hover .video {
  transform: scale(1); /* Expanded video is slightly larger */
}

.portfolio h1 {
  display: none;
}

.text-section h1 {
  display: block;
  font-size: 32px;
  color: aliceblue;
  padding-left: 25px;
}

.text-section p {
  font-size: 25px;
  color: aliceblue;
  padding-left: 28px;
}

@media screen and (max-width: 768px) {
  .portfolio {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
  }

  .portfolio h1 {
    display: block;
    font-size: 20px;
    color: aliceblue;
    text-align: center;
  }

  .text-section h1 {
    display: none;
    font-size: 20px;
    color: aliceblue;
  }
  
  .text-section p {
    font-size: 14px;
    color: aliceblue;
  }
}