

.contact {
  padding: 80px 0;
  background-color: #141c32 !important; /* Dark background for contrast */
  color: #f9f9f9;
  text-align: center;
}

.contact-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 40px;
  background-color: rgba(9, 17, 39, 0.6) !important; /* Semi-transparent white for elegance */
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2) !important; /* Softer shadow for depth */
}

.contact-form h2 {
  font-size: 28px; /* Slightly larger font size */
  margin-bottom: 30px; /* More space */
  color: #fff; /* White color for the heading */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: none; /* Remove border */
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 16px; /* Larger font size for readability */
}

.contact-form textarea {
  resize: vertical;
  min-height: 150px; /* More space for message */
}

.contact-form button {
  width: 100%;
  padding: 15px;
  background-color: #1E9CD9;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* Larger font size */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.contact-form button:hover {
  background-color: #147AA2; /* Darker shade for hover state */
}

@media screen and (max-width: 768px) {
  .contact-form {
    padding: 25px;
  }

  .contact-form h2 {
    font-size: 24px; /* Adjust font size for smaller screens */
  }
}
