
.about {
    display: flex;
    align-items: center;
    padding: 100px 0;
    height: 70vh;
    background-color: #141c32;
  }
  
  .about-video {
    flex: 50%; /* Set the video width to 30% */
    padding: 0 20px;
    height: auto; /* Set the video container height to auto */
  }
  
  .about-video video {
    max-width: 100%; /* Ensure the video does not exceed the container width */
  }
  
  .about-text {
    flex: 50%; /* Set the text width to 70% */
    padding: 0 20px;
    color: #f9f9f9;
  }

  .about-text p {
    font-size: 24px;
  }

  .about h1 {
    display: none;
  }

  .about-text h1 {
    display: block;
    font-size: 44px;
  }

  .down-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000; /* Change the color if needed */
  }

  a.see-more {
    text-decoration: none;
    display: flex;
    gap: 5px;
  }

  a.see-more p {
    font-size: 45px;
  }


  a.see-more:hover {
    text-decoration: none;
    display: flex;
    .see {
      color: #FFDF00;
    transition: 2s;
    }
    .more {
      color: #1A9CD9;
    transition: 2s;
    }
    .arrows {
      color: #FFDF00;
    transition: 2s;
    }
  }

  .see-more .see {
    color: #1A9CD9;
  }

  /* .see-more .see:hover {
    color: #FFDF00;
    transition: 5s;
  } */

  .see-more .more {
    color: #FFDF00;
  }

  /* .see-more .more:hover {
    color: #1A9CD9;
    transition: 5s;
  } */

  .arrows {
    color: #1A9CD9;
    padding: 0;
    margin: 0;
    font-size: 50px;
  }
  
  
  /* Add additional styling as needed */
  
  @media screen and (max-width: 768px) {
    .about {
      display: flex;
      flex-direction: column;
      padding: 100px 0;
      overflow: hidden;
    }
      .about {
        padding: 40px 20px; /* Adjust the padding */
        height: auto; /* Remove fixed height */
      }
    
      .about-video,
      .about-text {
        flex: 100%; /* Set both video and text to 100% width */
        padding: 0; /* Remove padding */
      }
    
      .about-text p {
        font-size: 18px; /* Adjust font size for smaller screens */
        text-align: center;
      }

      .about h1 {
        display: block;
        font-size: 28px; /* Adjust font size for smaller screens */
        color: #f9f9f9;
        text-align: center;
      }
    
      .about-text h1 {
        display: none;
      }
      
  }