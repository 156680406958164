

.filming-page {
    background-color: #141c32 !important;
    /* margin-top: 3%;
    padding-top: 18%; */
    min-height: 100vh;
  }
  
  .page-header {
    text-align: center;
    position: relative;
    background-color: #141c32 !important;

  }
  
  .rectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh; /* Adjust the height of the rectangular header */
  }
  
  .page-header h1 {
    font-size: 40px; /* Larger font size for the header */
    color: #e0e0e0; /* Soft white for the text */
    margin-bottom: 1rem; /* Spacing under the header text */
    font-weight: 700; /* Bolder font for impact */
}

.page-header p {
    font-size: 20px; /* Larger font size for subtext */
    color: #d1d1d1; /* Lighter color for contrast */
    max-width: 600px; /* Limiting width for better readability */
    margin: auto; /* Centering the paragraph */
}
  
 /* ... (Previous CSS rules) */

.video-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 0; /* Padding for top and bottom */
  }
  
  .video-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .video-container {
    margin: 10px;
    max-width: 400px;
    overflow: hidden;
    margin-bottom: 2rem; /* Space between video rows */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
    border-radius: 8px;
    /* width: 50%; Make each video container take 50% of the width */
  }
  
  .video-container video {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Rounded corners to match the container */
  }
  
  @media screen and (max-width: 768px) {
    .video-container {
      max-width: 100%;
    }

    .video-row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        flex-direction: column-reverse;
    }
  }
  