/* Services.css */

.services {
  padding: 20px;
  transition: opacity 0.5s ease-in-out;
  background-color: #141c32;
  color: #f9f9f9;
}

.services h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #f9f9f9;;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.service {
  background-color: rgba(9, 17, 39, 0.6);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(9, 17, 39);
  height: 350px;
}

.service img {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.service h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.service p {
  font-size: 16px;
}

/* Services.css */
@keyframes fadeInUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.services.animated {
  animation: fadeInUp 2s ease-in-out;
}

/* Apply the animation to the services container */
.services-container {
  animation: bounceIn 2.5s ease-in-out; /* Use the defined animation */
}

@media screen and (max-width: 768px) {
  .services {
    position: relative;
    overflow: hidden;
  }
}