.social-media {
  min-height: 90vh;
  background: linear-gradient(to bottom right, #1A9CD9 7%, #ffffff 50%, #FFDF00 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-fluid {
  max-width: 60%;
  height: auto;
}

.social-icon {
  color: #29ABE2; /* Use the color provided */
  font-size: 5.5rem; /* Adjust the size as needed */
  margin: 0 10px;
  --bs-gutter-x: .5rem;
}

/* Add your @font-face here if it's a custom font */
@font-face {
  font-family: 'STC';
  src: url('../images/growup2-2.png') format('woff');
  /* Include other formats as needed */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .social-icon {
    font-size: 2.5rem;
  }

  .textStyle {
    font-size: 15pt !important;
  }
}
