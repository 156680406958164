
.clients {
  background-color: #141c32;
  padding: 40px;
  color: #f9f9f9;
}

.clients h2 {
  color: #fff;
  font-size: 30px; /* Larger size for more impact */
  text-transform: uppercase; /* Professional touch */
  margin-bottom: 30px; /* More space between the heading and logos */
  text-align: center;
  letter-spacing: 1.5px; /* Spacing out letters for a refined look */
}

.client-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px; /* Increase gap for better spacing */
}

.client-logos img {
  max-width: 100%;
  height: auto;
  max-height: 80px; /* Adjust as needed */
  transition: transform 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.mb-3 {
  margin-bottom: 1rem !important;
  justify-content: center;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.client-logos img:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .clients h2 {
      font-size: 24px; /* Smaller font size for smaller screens */
  }
  .client-logos img {
      max-height: 60px; /* Smaller logos on smaller screens */
  }
}
