/* HeaderCaption.css */


.header-caption {
  text-align: center;
  z-index: 2;
  margin: 0;
  background-color: #141c32 !important;
  padding: 10px;
  color: honeydew;
  height: 50vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  
  .header-caption h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .header-caption p {
    font-size: 26px;
    padding: 10px 20px;
  }
  
  @media screen and (max-width: 768px) {
    .header-caption {
      position: relative;
      height: 30vh;
      overflow: hidden;
      justify-content: flex-start;
    }
    .header-caption h1 {
      font-size: 1.4rem;
      margin-bottom: 10px;
    }
    
    .header-caption p {
      font-size: 14px;
     
    }
  }