/* Footer.css */


.footer {
    background-color: #141c32 !important;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6) ;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .social-icons {
    display: flex;
    gap: 13px;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 35px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #00a0d1; /* Change the color on hover */
  }
  
  @media screen and (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      overflow: hidden;
    }
  }
  