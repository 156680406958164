/* NavBar.css */



.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(9, 17, 39, 0.6);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
}

.navbar {
  position: fixed; /* Changed to fixed */
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  z-index: 1;
}

.nav-logo img {
  width: auto; /* Adjust the logo size as needed */
  height: 50px;
}

.nav-logo img {
  height: 50px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
}


.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 20px;
}

.nav-item:last-child {
  margin-right: 0;
}

/* Media Query for Tablets and Smaller Screens */
@media screen and (max-width: 768px) {
  .nav {
    display: flex;
    /* padding: 10px; */
    /* max-width: 100%; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  /* .nav-logo img {
    width: 40px;
  } */

  .nav-item {
    margin-right: 10px;
    font-size: 14px;
  }
}

.nav-item {
  margin: 0 20px;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  transition: color 0.3s;
}

.nav-item a {
  display: flex;
  padding-bottom: 7px;
  font-size: 21px;
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.nav-item a:hover {
  color: #1e9cd9;
}

.nav-item.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #141c32;
  padding: 10px;
  z-index: 1;
  top: 100%;
  left: 0;
  
}

.dropdown-content a {
  display: block;
  padding: 5px 0;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.dropdown-content a:hover {
  color: #1e9cd9;
}

/* NavBar.css */
/* ... other styles ... */

.nav-item-text {
  display: inline-block;
  position: relative;
  font-size: 21px;
  padding-bottom: 7px;
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent; /* Adjust arrow size */
  border-right: 8px solid transparent; /* Adjust arrow size */
  border-top: 8px solid #fff; /* Adjust arrow color */
  top: 50%;
  transform: translateY(-50%);
  right: -20px; /* Adjust arrow position */
}

.arrow:hover {
  border-color: #1e9cd9;
  .nav-item {
    color: #1e9cd9;
  }
}
.nav-item:hover {
  color: #1e9cd9;
  .arrow {
    border-color: #1e9cd9;
  }
  
}

.nav-icon img {
  display: none;
}

/* ... other styles ... */

/* NavBar.css */
/* ... other styles ... */

/* Media Query for Mobile */
@media screen and (max-width: 768px) {
  .nav-list {
    display: none;
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
    text-align: center;
    flex-direction: column;
  }

  .nav-list.open {
    display: flex;
    padding-bottom: 10px;
  }

  .nav-item {
    margin: 10px 0;
  }

  .nav-icon {
    display: block;
    cursor: pointer;
    margin-right: 20px;
  }

  .nav-icon img {
    display: block;
    width: 30px;
    height: 30px;
    /* margin-right: 30px; */
  }
}

.nav-item.active {
  color: #1e9cd9;
}

.navbar-brand img {
  height: 50px; /* Adjust logo size as needed */
}

/* ... other styles ... */
